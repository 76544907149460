import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=5bf3238b&"
import script from "./Header.vue?vue&type=script&lang=js&"
export * from "./Header.vue?vue&type=script&lang=js&"
import style0 from "./Header.vue?vue&type=style&index=0&id=5bf3238b&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GeneralUiHeaderItems: require('/root/mohlel-front/components/general/ui/HeaderItems.vue').default,GeneralUiLanguageMenu: require('/root/mohlel-front/components/general/ui/LanguageMenu.vue').default,GeneralUiUserDropDown: require('/root/mohlel-front/components/general/ui/UserDropDown.vue').default,GeneralUiSvgIcon: require('/root/mohlel-front/components/general/ui/SvgIcon.vue').default,UserAdminUserToolbarSidebarItems: require('/root/mohlel-front/components/user-admin/user/toolbar/SidebarItems.vue').default})
