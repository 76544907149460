
import { mapState } from "vuex";
export default {
  name: "DefaultFooter",
  data() {
    return {
      fixed: false,
      loaded: false,
      market: [
        {
          txt: `$vuetify.pages.sectors`,
          url: "/sectors",
        },
        {
          txt: `$vuetify.home.market.dividend`,
          url: "/distributions",
        },
        // {
        //   txt: `$vuetify.home.market.courses`,
        //   url: "#",
        // },
        {
          txt: `$vuetify.home.market.news`,
          url: "/news",
          controlByRequest: true,
        },
        // {
        //   txt: `$vuetify.home.market.QA`,
        //   url: "/faqs",
        // },
      ],
      mohlel: [
        {
          txt: `$vuetify.home.mohlel.aboutUs`,
          url: "/about-us",
        },
        {
          txt: `$vuetify.home.mohlel.plans`,
          url: "/subscriptions",
        },
        {
          txt: `$vuetify.home.mohlel.terms_conditions`,
          url: "/terms-conditions",
        },
        // {
        //   txt: `$vuetify.home.mohlel.affiliate_marketing_program`,
        //   url: "#",
        // },
      ],
      form: {
        email: "",
      },
    };
  },
  computed: {
    ...mapState("home", ["siteSettings", "homeSections"]),
    info() {
      if (this.siteSettings) {
        return [
          {
            url: this.siteSettings.email,
            type: "email",
            icon: "Mail",
          },
          {
            url: this.siteSettings.phone,
            type: "phone",
            icon: "mdi mdi-whatsapp",
          },
          {
            url: this.siteSettings.address,
            icon: "Location",
          },
        ];
      }
    },
    media() {
      return [
        {
          icon: "Twitter",
          url: this.siteSettings?.twitter,
        },
        {
          icon: "LinkedIn",
          url: this.siteSettings?.linkedin,
        },
        {
          icon: "Instagram",
          url: this.siteSettings?.instagram,
        },
        {
          type: "mail",
          icon: "Mail",
          url: this.siteSettings?.email,
        },
      ];
    },
  },
  async fetch() {
    await this.$store.dispatch("home/getSections");
  },
  methods: {
    async onSubmit() {},
  },
};
