
export default {
  name: "DefaultLayout",
  // props: {
  //   loggedIn: {
  //     type: Boolean,
  //     required: false,
  //     default: false,
  //   },
  // },
  data() {
    return {
      drawer: false,
      dialog: false,
    };
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    toggleDialog() {
      this.dialog = !this.dialog;
    },
    async logOut() {
      await this.$auth.logout().then(() => {
        this.$router.push(this.localePath("/"));
      });
    },
  },
};
